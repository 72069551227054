//import Navbar, Container, Nav, NavDropdown from react-bootstrap
import { Navbar, Nav } from 'react-bootstrap';
//import react usestate and useefect
import { useState, useEffect } from 'react';

export default function Header() {

    const[classScroll, setClassScroll] = useState('');
    const[variantScroll, setVariantScroll] = useState('dark');
    const[sticky, setSticky] = useState([]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 75) {
                setClassScroll('nav-scroll');
                setVariantScroll('light');
                setSticky({ position: "sticky"});
            } else {
                setClassScroll('');
                setVariantScroll('dark');
                setSticky([]);
            }
        }
        , { passive: true });
    }
    , []);

    return (
        // header menu using react-bootstrap
            <Navbar  expand="lg" className={'change '+ classScroll} variant={variantScroll} style={sticky}>
                <Navbar.Brand className='m-3' href="#home">Productores Quinta Leyva</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#empresa">Empresa</Nav.Link>
                        <Nav.Link href="#equipo">Equipo</Nav.Link>
                        <Nav.Link href="#productos">Productos</Nav.Link>
                        <Nav.Link href="#contacto">Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    );
}