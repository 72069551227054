export default function Hero() {
    
    
    return (
        <div  id="home" className="hero-bg2" data-overlay-dark="1">
            <div className="container align-middle">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 ">
                        <div className="caption center mt-30 ">
                            <h1 className="font-weight-bold text-white text-center">Productores Quinta Leyva</h1>
                            <h2 className="text-center">Nuestra mision es dar a conocer al mundo el valor de la producción agrícola en el estado de guerrero, mediante la producción de cervezas y vinos artesanales.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}