// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

import producto1 from "../img/producto1.jpg";
import producto2 from "../img/producto2.jpg";
import producto3 from "../img/producto3.jpg";
import producto4 from "../img/producto4.jpg";
export default function Productos() {

    return (
        <div id="productos" className="historia" data-overlay-dark="1">
            <div className="container align-middle">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 ">
                        <div className="">
                            <h6 className="fw-300 text-u ls10 mb-10 text-dark text-center">CONOCE  NUESTRA MARCA PRINCIPAL</h6>
                            <h3 className="fw-600 text-u ls1 mb-30 color-font text-center">Tlacal vino tinto de flor de jamaica</h3>
                        </div>

                    </div>

                    <Swiper
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                              width: 640,
                              slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                              width: 768,
                              slidesPerView: 2,
                            },
                          }}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide><img src={producto3} alt="Vino Tlacal"></img></SwiperSlide>
                        <SwiperSlide><img src={producto4} alt="Vino Tlacal"></img></SwiperSlide>
                        <SwiperSlide><img src={producto2} alt="Vino Tlacal"></img></SwiperSlide>
                        <SwiperSlide><img src={producto1} alt="Vino Tlacal"></img></SwiperSlide>

                    </Swiper>

                </div>
            </div>
        </div>
    )

}