export default function Footer() {

    return (
        // basic footer using react-bootstrap
        <div className="footer" data-overlay-dark="6">
            <div className="footer-bottom">
                <div className="container">
                    <p className="copyright">
                        &copy; Copyright <strong>Productores Quinta Leyva</strong>. All Rights Reserved
                    </p>
                    <div className="credits">
                        Sitio diseñado por <a href="
                        ">Leyva[DEV]</a>
                    </div>
                </div>
            </div>
        </div>
    );
}