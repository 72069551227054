import Header from "./components/header";
import Hero from "./components/hero";
import Empresa from "./components/empresa";
import Equipo from "./components/equipo";
import Productos from "./components/productos";
import Contacto from "./components/contacto";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Empresa />
      <Equipo />
      <Productos />
      <Contacto />
      <Footer />
    </div>
  );
}

export default App;
