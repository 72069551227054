import team1 from "../img/team1.jpg";
import team2 from "../img/team2.jpg";
import team3 from "../img/team3.jpg";



export default function Equipo() {
    return (
        <div id="equipo" className="historia" data-overlay-dark="1">
            <div className="container">
                <div className="row">
                    <div className="valign md-mb50">
                        <div className="mb-50">
                            <h3 className="fw-600 text-u ls1 mb-30 color-font">Conoce nuestro equipo</h3>
                            <h6 className="fw-300 text-u ls10 mb-10 text-dark">Ellos son visionarios y emprendedores</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 valign md-mb50">
                        <div className="card container2" data-overlay-dark="1">
                            <div className="container2">
                                <img className="image2" src={team1} alt="Avatar" style={{ width: "100%" }}></img>
                                <div className="overlay2" >
                                    <div className="text2">Dar valor agregado a la producción agrícola local.</div>
                                </div>
                            </div>
                            <div className="card-title">
                                <h4 className="fw-600 text-u ls1 text-dark text-center">Marcelino Leyva</h4>
                                <h6 className="fw-600  text-muted text-center">REPRESENTANTE SOCIAL</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 valign md-mb50">
                        <div className="card container2" data-overlay-dark="1">
                            <div className="container2">
                                <img className="image2" src={team2}  alt="Avatar" style={{ width: "100%" }}></img>
                                <div className="overlay2" >
                                    <div className="text2">Logística y distribución ordenados.</div>
                                </div>
                            </div>
                            <div className="card-title">
                                <h4 className="fw-600 text-u ls1 text-dark text-center">Adriana Luna</h4>
                                <h6 className="fw-600  text-muted text-center">DISTRITAL DE DISTRIBUCIÓN</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 valign md-mb50">
                        <div className="card container2" data-overlay-dark="1">
                            <div className="container2">
                                <img className="image2" src={team3} alt="Avatar" style={{ width: "100%" }}></img>
                                <div className="overlay2" >
                                    <div className="text2">Extender el valor de la producción local en nuevos canales de distribución.</div>
                                </div>
                            </div>
                            <div className="card-title">
                                <h4 className="fw-400 text-u ls1 text-dark text-center">Cesar Leyva</h4>
                                <h6 className="fw-600  text-muted text-center">COMERCIO ELECTRONICO</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

