// importamos useState y useEffect
import { useState, useEffect } from 'react';
import alimentaria from "../img/alimentaria.jpg";
import portada from "../img/portada.jpg";

export default function Contacto() {
    // creamos los estados para el formulario con los valores por defecto
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState(false);
    const [enviado, setEnviado] = useState(false);




    return (
        <div id="contacto" className="historia" data-overlay-dark="1">
            <div className="container align-middle">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 ">
                        <div className="">
                            <h3 className="fw-600 text-u ls1 mb-30 color-font text-center">Contáctanos</h3>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-11 ">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input type="text" className="form-control" id="nombre" placeholder="Nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
                                        <label htmlFor="telefono">Teléfono</label>
                                        <input type="text" className="form-control" id="telefono" placeholder="Teléfono" value={telefono} onChange={e => setTelefono(e.target.value)} />
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <label htmlFor="mensaje">Mensaje</label>
                                        <textarea className="form-control" id="mensaje" rows="3" placeholder="Mensaje" value={mensaje} onChange={e => setMensaje(e.target.value)} />
                                        <button type="submit" className="btn btn-primary btn-block">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 ">
                                    <div className="d-flex  justify-content-center">
                                        <img src={alimentaria} className="rounded-circle flotante" alt="Marcelino" ></img>
                                    </div>
                                    <div className="">
                                        <h6 className="fw-300 text-u ls10 mb-10 text-dark text-center">Creamos valor en la producción agrícola</h6>
                                        <h5 className="fw-500 text-u ls1 mb-30 color-font text-center">"Agregamos valor de diferentes formas a la producción local, reactivando en pequeñas esferas la economía del productor agrícola." <br></br>-Marcelino Leyva</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex  justify-content-center">
                    <img src={portada} className="portada" alt="Portasa Tlacal" ></img>
                </div>
            </div>
        </div>
    )
}