import dea from "../img/dea.png";
// import button from reat-bootstrap/Button";
import { Button } from "react-bootstrap";

export default function Empresa() {
    return (
        <div id="empresa" className="historia" data-overlay-dark="1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 valign md-mb50">
                        <div className="mb-50">
                            <h6 className="fw-300 text-u ls10 mb-10 text-dark">CONOCE NUESTRA HISTORIA</h6>
                            <h3 className="fw-600 text-u ls1 mb-30 color-font">Haciendo un vino tinto delicioso desde 2010</h3>
                            <p className="text-dark">Desde la siembra hasta la cosecha nos enfocamos en darle un valor agregado a la producción local, cuidando hasta el más mínimo detalle, esta propuesta en vinos y cervezas artesanales no solo beneficia al consumidor se extiende beneficiando al pequeño productor agrícola local, reactivando la economía dentro de la región.

                                Somos una pyme con deseos de crecer, si te interesa conocer mas de nosotros puedes seguirnos en redes sociales.</p>
                            <div>
                                <Button variant="primary" href="https://www.facebook.com/productoresquintaleyva/">
                                    <span className="fab fa-facebook-f social-icon2 "></span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 img">
                        <img src={dea} alt="Promotor con botella de vino"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}